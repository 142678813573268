export const pages_data = [
  {
    key: "1",
    page: "Students",
    slug: "students",
    status: true,
  },
  {
    key: "2",
    page: "Teachers",
    slug: "teachers",
    status: true,
  },
  {
    key: "3",
    page: "Parents",
    slug: "parents",
    status: true,
  },
  {
    key: "4",
    page: "Guardians",
    slug: "guardians",
    status: true,
  },
  {
    key: "5",
    page: "Classes",
    slug: "classes",
    status: true,
  },
  {
    key: "6",
    page: "Hostel",
    slug: "hostel",
    status: true,
  },
];
