export const classSyllabus = [
	{
		id: "1",
		class: "I",
		section: "A",
		subjectGroup: "I, C English",
		createdDate: "10 May 2024",
		status: "Active",
		key:1,
	},
	{
		id: "2",
		class: "I",
		section: "B",
		subjectGroup: "III, A Maths",
		createdDate: "11 May 2024",
		status: "Active",
		key:2,
	},
	{
		id: "3",
		class: "II",
		section: "A",
		subjectGroup: "II, A English",
		createdDate: "12 May 2024",
		status: "Active",
		key:3,
	},
	{
		id: "4",
		class: "II",
		section: "B",
		subjectGroup: "IV, A Physics",
		createdDate: "13 May 2024",
		status: "Active",
		key:4,
	},
	{
		id: "5",
		class: "II",
		section: "C",
		subjectGroup: "V, A Chemistry",
		createdDate: "14 May 2024",
		status: "Active",
		key:5,
	},
	{
		id: "6",
		class: "III",
		section: "A",
		subjectGroup: "III, B Maths",
		createdDate: "15 May 2024",
		status: "Active",
		key:6,
	},
	{
		id: "7",
		class: "III",
		section: "B",
		subjectGroup: "IV, B Chemistry",
		createdDate: "16 May 2024",
		status: "Active",
		key:7,
	},
	{
		id: "8",
		class: "IV",
		section: "A",
		subjectGroup: "I, B Maths",
		createdDate: "17 May 2024",
		status: "Active",
		key:11,
	},
	{
		id: "9",
		class: "IV",
		section: "B",
		subjectGroup: "VI, B Chemistry",
		createdDate: "18 May 2024",
		status: "Active",
		key:8,
	},
	{
		id: "10",
		class: "V",
		section: "A",
		subjectGroup: "IV, D Maths",
		createdDate: "19 May 2024",
		status: "Active",
		key:9,
	}
];
