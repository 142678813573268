import React from 'react'

const FontawesomeIcons = () => {
  return (
    <div>
        <div className="page-wrapper cardhead">
        <div className="content container-fluid">
          {/* Page Header */}
          <div className="page-header">
            <div className="row">
              <div className="col-sm-12">
                <h3 className="page-title">Fontawesome Icon</h3>
              </div>
            </div>
          </div>	
          {/* /Page Header */}					
          <div className="row">
            {/* Chart */}
            <div className="col-md-12">	
              <div className="card">
                <div className="card-header">
                  <div className="card-title">Fontawesome Icon</div>
                </div>
                <div className="card-body">
                  <div className="icons-items">
                    <ul className="icons-list">
                      <li><i className="fa fa-address-book" data-bs-toggle="tooltip" title="fa fa-address-book" /></li>
                      <li><i className="fa fa-address-card" data-bs-toggle="tooltip" title="fa fa-address-card" /></li>
                      <li><i className="fa fa-align-center" data-bs-toggle="tooltip" title="fa fa-align-center" /></li>
                      <li><i className="fa fa-align-justify" data-bs-toggle="tooltip" title="fa fa-align-justify" /></li>
                      <li><i className="fa fa-align-left" data-bs-toggle="tooltip" title="fa fa-align-left" /></li>
                      <li><i className="fa fa-align-right" data-bs-toggle="tooltip" title="fa fa-align-right" /></li>
                      <li><i className="fa fa-ambulance" data-bs-toggle="tooltip" title="fa fa-ambulance" /></li>
                      <li><i className="fa fa-american-sign-language-interpreting" data-bs-toggle="tooltip" title="fa fa-american-sign-language-interpreting" /></li>
                      <li><i className="fa fa-anchor" data-bs-toggle="tooltip" title="fa fa-anchor" /></li>
                      <li><i className="fa fa-angle-double-down" data-bs-toggle="tooltip" title="fa fa-angle-double-down" /></li>
                      <li><i className="fa fa-angle-double-left" data-bs-toggle="tooltip" title="fa fa-angle-double-left" /></li>
                      <li><i className="fa fa-angle-double-right" data-bs-toggle="tooltip" title="fa fa-angle-double-right" /></li>
                      <li><i className="fa fa-angle-double-up" data-bs-toggle="tooltip" title="fa fa-angle-double-up" /></li>
                      <li><i className="fa fa-angle-down" data-bs-toggle="tooltip" title="fa fa-angle-down" /></li>
                      <li><i className="fa fa-angle-left" data-bs-toggle="tooltip" title="fa fa-angle-left" /></li>
                      <li><i className="fa fa-angle-right" data-bs-toggle="tooltip" title="fa fa-angle-right" /></li>
                      <li><i className="fa fa-angle-up" data-bs-toggle="tooltip" title="fa fa-angle-up" /></li>
                      <li><i className="fab fa-apple" data-bs-toggle="tooltip" title="fab fa-apple" /></li>
                      <li><i className="fa fa-archive" data-bs-toggle="tooltip" title="fa fa-archive" /></li>
                      <li><i className="fas fa-chart-area" data-bs-toggle="tooltip" title="fas fa-chart-area" /></li>
                      <li><i className="fa fa-arrow-circle-down" data-bs-toggle="tooltip" title="fa fa-arrow-circle-down" /></li>
                      <li><i className="fa fa-arrow-circle-left" data-bs-toggle="tooltip" title="fa fa-arrow-circle-left" /></li>
                      <li><i className="fa fa-arrow-circle-right" data-bs-toggle="tooltip" title="fa fa-arrow-circle-right" /></li>
                      <li><i className="fa fa-arrow-circle-up" data-bs-toggle="tooltip" title="fa fa-arrow-circle-up" /></li>
                      <li><i className="fa fa-arrow-down" data-bs-toggle="tooltip" title="fa fa-arrow-down" /></li>
                      <li><i className="fa fa-arrow-left" data-bs-toggle="tooltip" title="fa fa-arrow-left" /></li>
                      <li><i className="fa fa-arrow-right" data-bs-toggle="tooltip" title="fa fa-arrow-right" /></li>
                      <li><i className="fa fa-arrow-up" data-bs-toggle="tooltip" title="fa fa-arrow-up" /></li>
                      <li><i className="fa fa-arrows-alt" data-bs-toggle="tooltip" title="fa fa-arrows-alt" /></li>
                      <li><i className="fa fa-assistive-listening-systems" data-bs-toggle="tooltip" title="fa fa-assistive-listening-systems" /></li>
                      <li><i className="fa fa-asterisk" data-bs-toggle="tooltip" title="fa fa-asterisk" /></li>
                      <li><i className="fa fa-at" data-bs-toggle="tooltip" title="fa fa-at" /></li>
                      <li><i className="fa fa-audio-description" data-bs-toggle="tooltip" title="fa fa-audio-description" /></li>
                      <li><i className="fa fa-backward" data-bs-toggle="tooltip" title="fa fa-backward" /></li>
                      <li><i className="fa fa-balance-scale" data-bs-toggle="tooltip" title="fa fa-balance-scale" /></li>
                      <li><i className="fa fa-ban" data-bs-toggle="tooltip" title="fa fa-ban" /></li>
                      <li><i className="fa fa-barcode" data-bs-toggle="tooltip" title="fa fa-barcode" /></li>
                      <li><i className="fa fa-bars" data-bs-toggle="tooltip" title="fa fa-bars" /></li>
                      <li><i className="fa fa-bath" data-bs-toggle="tooltip" title="fa fa-bath" /></li>
                      <li><i className="fa fa-battery-empty" data-bs-toggle="tooltip" title="fa fa-battery-empty" /></li>
                      <li><i className="fa fa-battery-full" data-bs-toggle="tooltip" title="fa fa-battery-full" /></li>
                      <li><i className="fa fa-battery-half" data-bs-toggle="tooltip" title="fa fa-battery-half" /></li>
                      <li><i className="fa fa-battery-quarter" data-bs-toggle="tooltip" title="fa fa-battery-quarter" /></li>
                      <li><i className="fa fa-battery-three-quarters" data-bs-toggle="tooltip" title="fa fa-battery-three-quarters" /></li>
                      <li><i className="fa fa-bed" data-bs-toggle="tooltip" title="fa fa-bed" /></li>
                      <li><i className="fa fa-beer" data-bs-toggle="tooltip" title="fa fa-beer" /></li>
                      <li><i className="fa fa-bell" data-bs-toggle="tooltip" title="fa fa-bell" /></li>
                      <li><i className="fa fa-bell-slash" data-bs-toggle="tooltip" title="fa fa-bell-slash" /></li>
                      <li><i className="fa fa-bicycle" data-bs-toggle="tooltip" title="fa fa-bicycle" /></li>
                      <li><i className="fa fa-binoculars" data-bs-toggle="tooltip" title="fa fa-binoculars" /></li>
                      <li><i className="fa fa-birthday-cake" data-bs-toggle="tooltip" title="fa fa-birthday-cake" /></li>
                      <li><i className="fa fa-blind" data-bs-toggle="tooltip" title="fa fa-blind" /></li>
                      <li><i className="fa fa-bold" data-bs-toggle="tooltip" title="fa fa-bold" /></li>
                      <li><i className="fa fa-bolt" data-bs-toggle="tooltip" title="fa fa-bolt" /></li>
                      <li><i className="fa fa-bomb" data-bs-toggle="tooltip" title="fa fa-bomb" /></li>
                      <li><i className="fa fa-book" data-bs-toggle="tooltip" title="fa fa-book" /></li>
                      <li><i className="fa fa-bookmark" data-bs-toggle="tooltip" title="fa fa-bookmark" /></li>
                      <li><i className="fa fa-braille" data-bs-toggle="tooltip" title="fa fa-braille" /></li>
                      <li><i className="fa fa-briefcase" data-bs-toggle="tooltip" title="fa fa-briefcase" /></li>	
                      <li><i className="fa fa-bug" data-bs-toggle="tooltip" title="fa fa-bug" /></li>
                      <li><i className="fa fa-building" data-bs-toggle="tooltip" title="fa fa-building" /></li>
                      <li><i className="fa fa-bullhorn" data-bs-toggle="tooltip" title="fa fa-bullhorn" /></li>
                      <li><i className="fa fa-bullseye" data-bs-toggle="tooltip" title="fa fa-bullseye" /></li>
                      <li><i className="fa fa-bus" data-bs-toggle="tooltip" title="fa fa-bus" /></li>
                      <li><i className="fa fa-calculator" data-bs-toggle="tooltip" title="fa fa-calculator" /></li>
                      <li><i className="fa fa-calendar" data-bs-toggle="tooltip" title="fa fa-calendar" /></li>
                      <li><i className="fa fa-camera" data-bs-toggle="tooltip" title="fa fa-camera" /></li>
                      <li><i className="fa fa-camera-retro" data-bs-toggle="tooltip" title="fa fa-camera-retro" /></li>
                      <li><i className="fa fa-car" data-bs-toggle="tooltip" title="fa fa-car" /></li>
                      <li><i className="fa fa-caret-down" data-bs-toggle="tooltip" title="fa fa-caret-down" /></li>
                      <li><i className="fa fa-caret-left" data-bs-toggle="tooltip" title="fa fa-caret-left" /></li>
                      <li><i className="fa fa-caret-right" data-bs-toggle="tooltip" title="fa fa-caret-right" /></li>
                      <li><i className="fa fa-cart-arrow-down" data-bs-toggle="tooltip" title="fa fa-cart-arrow-down" /></li>
                      <li><i className="fa fa-cart-plus" data-bs-toggle="tooltip" title="fa fa-cart-plus" /></li>
                      <li><i className="fa fa-certificate" data-bs-toggle="tooltip" title="fa fa-certificate" /></li>
                      <li><i className="fa fa-check" data-bs-toggle="tooltip" title="fa fa-check" /></li>
                      <li><i className="fa fa-check-circle" data-bs-toggle="tooltip" title="fa fa-check-circle" /></li>
                      <li><i className="fa fa-chevron-circle-left" data-bs-toggle="tooltip" title="fa fa-chevron-circle-left" /></li>
                      <li><i className="fa fa-chevron-circle-right" data-bs-toggle="tooltip" title="fa fa-chevron-circle-right" /></li>
                      <li><i className="fa fa-chevron-circle-up" data-bs-toggle="tooltip" title="fa fa-chevron-circle-up" /></li>
                      <li><i className="fa fa-chevron-down" data-bs-toggle="tooltip" title="fa fa-chevron-down" /></li>
                      <li><i className="fa fa-chevron-left" data-bs-toggle="tooltip" title="fa fa-chevron-left" /></li>
                      <li><i className="fa fa-chevron-right" data-bs-toggle="tooltip" title="fa fa-chevron-right" /></li>
                      <li><i className="fa fa-chevron-up" data-bs-toggle="tooltip" title="fa fa-chevron-up" /></li>
                      <li><i className="fa fa-child" data-bs-toggle="tooltip" title="fa fa-child" /></li>
                      <li><i className="fa fa-circle" data-bs-toggle="tooltip" title="fa fa-circle" /></li>
                      <li><i className="fa fa-clipboard" data-bs-toggle="tooltip" title="fa fa-clipboard" /></li>
                      <li><i className="fa fa-clone" data-bs-toggle="tooltip" title="fa fa-clone" /></li>
                      <li><i className="fa fa-cloud" data-bs-toggle="tooltip" title="fa fa-cloud" /></li>
                      <li><i className="fa fa-code" data-bs-toggle="tooltip" title="fa fa-code" /></li>
                      <li><i className="fa fa-coffee" data-bs-toggle="tooltip" title="fa fa-coffee" /></li>
                      <li><i className="fa fa-cog" data-bs-toggle="tooltip" title="fa fa-cog" /></li>
                      <li><i className="fa fa-cogs" data-bs-toggle="tooltip" title="fa fa-cogs" /></li>
                      <li><i className="fa fa-columns" data-bs-toggle="tooltip" title="fa fa-columns" /></li>
                      <li><i className="fa fa-comment" data-bs-toggle="tooltip" title="fa fa-comment" /></li>
                      <li><i className="fa fa-compress" data-bs-toggle="tooltip" title="fa fa-compress" /></li>
                      <li><i className="fa fa-copyright" data-bs-toggle="tooltip" title="fa fa-copyright" /></li>
                      <li><i className="fa fa-credit-card" data-bs-toggle="tooltip" title="fa fa-credit-card" /></li>
                      <li><i className="fa fa-desktop" data-bs-toggle="tooltip" title="fa fa-desktop" /></li>
                      <li><i className="fa fa-edit" data-bs-toggle="tooltip" title="fa fa-edit" /></li>
                      <li><i className="fa fa-eject" data-bs-toggle="tooltip" title="fa fa-eject" /></li>
                      <li><i className="fa fa-ellipsis-h" data-bs-toggle="tooltip" title="fa fa-ellipsis-h" /></li>
                      <li><i className="fa fa-ellipsis-v" data-bs-toggle="tooltip" title="fa fa-ellipsis-v" />
                      </li><li><i className="fa fa-envelope" data-bs-toggle="tooltip" title="fa fa-envelope" /></li>
                      <li><i className="fa fa-envelope-open" data-bs-toggle="tooltip" title="fa fa-envelope-open" /></li>
                      <li><i className="fa fa-envelope-square" data-bs-toggle="tooltip" title="fa fa-envelope-square" /></li>
                      <li><i className="fa fa-eraser" data-bs-toggle="tooltip" title="fa fa-eraser" /></li>
                      <li><i className="fa fa-exclamation" data-bs-toggle="tooltip" title="fa fa-exclamation" /></li>
                      <li><i className="fa fa-exclamation-circle" data-bs-toggle="tooltip" title="fa fa-exclamation-circle" /></li>
                      <li><i className="fa fa-exclamation-triangle" data-bs-toggle="tooltip" title="fa fa-exclamation-triangle" /></li>
                      <li><i className="fa fa-expand" data-bs-toggle="tooltip" title="fa fa-expand" /></li>
                      <li><i className="fa fa-eye" data-bs-toggle="tooltip" title="fa fa-eye" /></li>
                      <li><i className="fa fa-eye-slash" data-bs-toggle="tooltip" title="fa fa-eye-slash" /></li>
                      <li><i className="fa fa-fast-backward" data-bs-toggle="tooltip" title="fa fa-fast-backward" /></li>
                      <li><i className="fa fa-fast-forward" data-bs-toggle="tooltip" title="fa fa-fast-forward" /></li>
                      <li><i className="fa fa-fax" data-bs-toggle="tooltip" title="fa fa-fax" /></li>
                      <li><i className="fa fa-female" data-bs-toggle="tooltip" title="fa fa-female" /></li>
                      <li><i className="fa fa-fighter-jet" data-bs-toggle="tooltip" title="fa fa-fighter-jet" /></li>
                      <li><i className="fa fa-file" data-bs-toggle="tooltip" title="fa fa-file" /></li>
                      <li><i className="fa fa-fire" data-bs-toggle="tooltip" title="fa fa-fire" /></li>
                      <li><i className="fa fa-fire-extinguisher" data-bs-toggle="tooltip" title="fa fa-fire-extinguisher" /></li>
                      <li><i className="fa fa-flag" data-bs-toggle="tooltip" title="fa fa-flag" /></li>
                      <li><i className="fa fa-flag-checkered" data-bs-toggle="tooltip" title="fa fa-flag-checkered" /></li>
                      <li><i className="fa fa-road" data-bs-toggle="tooltip" title="fa fa-road" /></li>
                      <li><i className="fa fa-rocket" data-bs-toggle="tooltip" title="fa fa-rocket" /></li>
                      <li><i className="fa fa-save" data-bs-toggle="tooltip" title="fa fa-save" /></li>
                      <li><i className="fa fa-search" data-bs-toggle="tooltip" title="fa fa-search" /></li>
                      <li><i className="fa fa-search-minus" data-bs-toggle="tooltip" title="fa fa-search-minus" /></li>
                      <li><i className="fa fa-search-plus" data-bs-toggle="tooltip" title="fa fa-search-plus" /></li>
                      <li><i className="fa fa-server" data-bs-toggle="tooltip" title="fa fa-server" /></li>
                      <li><i className="fa fa-share" data-bs-toggle="tooltip" title="fa fa-share" /></li>
                      <li><i className="fa fa-share-alt" data-bs-toggle="tooltip" title="fa fa-share-alt" /></li>
                      <li><i className="fa fa-share-alt-square" data-bs-toggle="tooltip" title="fa fa-share-alt-square" /></li>
                      <li><i className="fa fa-share-square" data-bs-toggle="tooltip" title="fa fa-share-square" /></li>
                      <li><i className="fa fa-ship" data-bs-toggle="tooltip" title="fa fa-ship" /></li>
                      <li><i className="fa fa-shopping-bag" data-bs-toggle="tooltip" title="fa fa-shopping-bag" /></li>
                      <li><i className="fa fa-shopping-basket" data-bs-toggle="tooltip" title="fa fa-shopping-basket" /></li>
                      <li><i className="fa fa-shopping-cart" data-bs-toggle="tooltip" title="fa fa-shopping-cart" /></li>
                      <li><i className="fa fa-shower" data-bs-toggle="tooltip" title="fa fa-shower" /></li>
                      <li><i className="fa fa-sign-language" data-bs-toggle="tooltip" title="fa fa-sign-language" /></li>
                      <li><i className="fa fa-signal" data-bs-toggle="tooltip" title="fa fa-signal" /></li>
                      <li><i className="fa fa-sitemap" data-bs-toggle="tooltip" title="fa fa-sitemap" /></li>
                      <li><i className="fa fa-sort" data-bs-toggle="tooltip" title="fa fa-sort" /></li>
                      <li><i className="fa fa-sort-down" data-bs-toggle="tooltip" title="fa fa-sort-down" /></li>
                      <li><i className="fa fa-square" data-bs-toggle="tooltip" title="fa fa-square" /></li>
                      <li><i className="fa fa-star" data-bs-toggle="tooltip" title="fa fa-star" /></li>
                      <li><i className="fa fa-star-half" data-bs-toggle="tooltip" title="fa fa-star-half" /></li>
                      <li><i className="fa fa-step-backward" data-bs-toggle="tooltip" title="fa fa-step-backward" /></li>
                      <li><i className="fa fa-step-forward" data-bs-toggle="tooltip" title="fa fa-step-forward" /></li>
                      <li><i className="fa fa-stethoscope" data-bs-toggle="tooltip" title="fa fa-stethoscope" /></li>
                      <li><i className="fa fa-sticky-note" data-bs-toggle="tooltip" title="fa fa-sticky-note" /></li>
                      <li><i className="fa fa-stop" data-bs-toggle="tooltip" title="fa fa-stop" /></li>
                      <li><i className="fa fa-stop-circle" data-bs-toggle="tooltip" title="fa fa-stop-circle" /></li>
                      <li><i className="fa fa-street-view" data-bs-toggle="tooltip" title="fa fa-street-view" /></li>
                      <li><i className="fa fa-subscript" data-bs-toggle="tooltip" title="fa fa-subscript" /></li>
                      <li><i className="fa fa-suitcase" data-bs-toggle="tooltip" title="fa fa-suitcase" /></li>
                      <li><i className="fa fa-superscript" data-bs-toggle="tooltip" title="fa fa-superscript" /></li>
                      <li><i className="fa fa-table" data-bs-toggle="tooltip" title="fa fa-table" /></li>
                      <li><i className="fa fa-tag" data-bs-toggle="tooltip" title="fa fa-tag" /></li>
                      <li><i className="fa fa-tags" data-bs-toggle="tooltip" title="fa fa-tags" /></li>
                      <li><i className="fa fa-tasks" data-bs-toggle="tooltip" title="fa fa-tasks" /></li>
                      <li><i className="fa fa-taxi" data-bs-toggle="tooltip" title="fa fa-taxi" /></li>
                      <li><i className="fa fa-terminal" data-bs-toggle="tooltip" title="fa fa-terminal" /></li>
                      <li><i className="fa fa-text-height" data-bs-toggle="tooltip" title="fa fa-text-height" /></li>
                      <li><i className="fa fa-text-width" data-bs-toggle="tooltip" title="fa fa-text-width" /></li>
                      <li><i className="fa fa-th" data-bs-toggle="tooltip" title="fa fa-th" /></li>
                      <li><i className="fa fa-th-large" data-bs-toggle="tooltip" title="fa fa-th-large" /></li>
                      <li><i className="fa fa-th-list" data-bs-toggle="tooltip" title="fa fa-th-list" /></li>
                      <li><i className="fa fa-thermometer" data-bs-toggle="tooltip" title="fa fa-thermometer" /></li>
                      <li><i className="fa fa-thermometer-empty" data-bs-toggle="tooltip" title="fa fa-thermometer-empty" /></li>
                      <li><i className="fa fa-thermometer-full" data-bs-toggle="tooltip" title="fa fa-thermometer-full" /></li>
                      <li><i className="fa fa-thermometer-half" data-bs-toggle="tooltip" title="fa fa-thermometer-half" /></li>
                      <li><i className="fa fa-thermometer-quarter" data-bs-toggle="tooltip" title="fa fa-thermometer-quarter" /></li>
                      <li><i className="fa fa-thermometer-three-quarters" data-bs-toggle="tooltip" title="fa fa-thermometer-three-quarters" /></li>
                      <li><i className="fa fa-thumbs-down" data-bs-toggle="tooltip" title="fa fa-thumbs-down" /></li>
                      <li><i className="fa fa-thumbs-up" data-bs-toggle="tooltip" title="fa fa-thumbs-up" /></li>
                      <li><i className="fa fa-times" data-bs-toggle="tooltip" title="fa fa-times" /></li>
                      <li><i className="fa fa-times-circle" data-bs-toggle="tooltip" title="fa fa-times-circle" /></li>
                      <li><i className="fa fa-tint" data-bs-toggle="tooltip" title="fa fa-tint" /></li>
                      <li><i className="fa fa-toggle-off" data-bs-toggle="tooltip" title="fa fa-toggle-off" /></li>
                      <li><i className="fa fa-toggle-on" data-bs-toggle="tooltip" title="fa fa-toggle-on" /></li>
                      <li><i className="fa fa-trademark" data-bs-toggle="tooltip" title="fa fa-trademark" /></li>
                      <li><i className="fa fa-train" data-bs-toggle="tooltip" title="fa fa-train" /></li>
                      <li><i className="fa fa-transgender" data-bs-toggle="tooltip" title="fa fa-transgender" /></li>
                      <li><i className="fa fa-transgender-alt" data-bs-toggle="tooltip" title="fa fa-transgender-alt" /></li>
                      <li><i className="fa fa-trash" data-bs-toggle="tooltip" title="fa fa-trash" /></li>
                      <li><i className="fa fa-tree" data-bs-toggle="tooltip" title="fa fa-tree" /></li>
                      <li><i className="fa fa-trophy" data-bs-toggle="tooltip" title="fa fa-trophy" /></li>
                      <li><i className="fa fa-tty" data-bs-toggle="tooltip" title="fa fa-tty" /></li>
                      <li><i className="fa fa-tv" data-bs-toggle="tooltip" title="fa fa-tv" /></li>
                      <li><i className="fa fa-umbrella" data-bs-toggle="tooltip" title="fa fa-umbrella" /></li>
                      <li><i className="fa fa-underline" data-bs-toggle="tooltip" title="fa fa-underline" /></li>
                      <li><i className="fa fa-undo" data-bs-toggle="tooltip" title="fa fa-undo" /></li>
                      <li><i className="fa fa-universal-access" data-bs-toggle="tooltip" title="fa fa-universal-access" /></li>
                      <li><i className="fa fa-university" data-bs-toggle="tooltip" title="fa fa-university" /></li>
                      <li><i className="fa fa-unlink" data-bs-toggle="tooltip" title="fa fa-unlink" /></li>
                      <li><i className="fa fa-unlock" data-bs-toggle="tooltip" title="fa fa-unlock" /></li>
                      <li><i className="fa fa-unlock-alt" data-bs-toggle="tooltip" title="fa fa-unlock-alt" /></li>
                      <li><i className="fa fa-upload" data-bs-toggle="tooltip" title="fa fa-upload" /></li>
                      <li><i className="fa fa-user-circle" data-bs-toggle="tooltip" title="fa fa-user-circle" /></li>
                      <li><i className="fa fa-user-md" data-bs-toggle="tooltip" title="fa fa-user-md" /></li>
                      <li><i className="fa fa-user-plus" data-bs-toggle="tooltip" title="fa fa-user-plus" /></li>
                      <li><i className="fa fa-user-secret" data-bs-toggle="tooltip" title="fa fa-user-secret" /></li>
                      <li><i className="fa fa-user-times" data-bs-toggle="tooltip" title="fa fa-user-times" /></li>
                      <li><i className="fa fa-users" data-bs-toggle="tooltip" title="fa fa-users" /></li>
                      <li><i className="fa fa-venus" data-bs-toggle="tooltip" title="fa fa-venus" /></li>
                      <li><i className="fa fa-venus-double" data-bs-toggle="tooltip" title="fa fa-venus-double" /></li>
                      <li><i className="fa fa-venus-mars" data-bs-toggle="tooltip" title="fa fa-venus-mars" /></li>
                      <li><i className="fa fa-volume-down" data-bs-toggle="tooltip" title="fa fa-volume-down" /></li>
                      <li><i className="fa fa-volume-off" data-bs-toggle="tooltip" title="fa fa-volume-off" /></li>
                      <li><i className="fa fa-volume-up" data-bs-toggle="tooltip" title="fa fa-volume-up" /></li>
                      <li><i className="fa fa-wheelchair" data-bs-toggle="tooltip" title="fa fa-wheelchair" /></li>
                      <li><i className="fa fa-wifi" data-bs-toggle="tooltip" title="fa fa-wifi" /></li>
                      <li><i className="fa fa-window-close" data-bs-toggle="tooltip" title="fa fa-window-close" /></li>
                      <li><i className="fa fa-window-maximize" data-bs-toggle="tooltip" title="fa fa-window-maximize" /></li>
                      <li><i className="fa fa-window-minimize" data-bs-toggle="tooltip" title="fa fa-window-minimize" /></li>
                      <li><i className="fa fa-window-restore" data-bs-toggle="tooltip" title="fa fa-window-restore" /></li>
                      <li><i className="fa fa-wrench" data-bs-toggle="tooltip" title="fa fa-wrench" /></li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            {/* /Chart */}
          </div>
        </div>		
      </div>
    </div>
  )
}

export default FontawesomeIcons