export const expense_data = [
  {
    key: "1",
    id: "EX628148",
    expenseName: "Monthly Electricity",
    description: "Electricity of April month",
    category: "Utilities",
    date: "25 Apr 2024",
    amount: "$1000",
    invoiceNo: "INV681537",
    paymentMethod: "Cash",
  },
  {
    key: "2",
    id: "EX628147",
    expenseName: "Teacher Salary",
    description: "April payroll for teaching staff",
    category: "Salaries",
    date: "29 Apr 2024",
    amount: "$20,000",
    invoiceNo: "INV681536",
    paymentMethod: "Credit",
  },
  {
    key: "3",
    id: "EX628146",
    expenseName: "AC Repair",
    description: "Air Conditioning repair",
    category: "Maintenance",
    date: "11 May 2024",
    amount: "$400",
    invoiceNo: "INV681535",
    paymentMethod: "Cash",
  },
  {
    key: "4",
    id: "EX628149",
    expenseName: "Office Supplies",
    description: "Stationery and office supplies for May",
    category: "Supplies",
    date: "15 May 2024",
    amount: "$200",
    invoiceNo: "INV681538",
    paymentMethod: "Debit",
  },
  {
    key: "5",
    id: "EX628150",
    expenseName: "Internet Bill",
    description: "Monthly internet charges",
    category: "Utilities",
    date: "20 May 2024",
    amount: "$150",
    invoiceNo: "INV681539",
    paymentMethod: "Credit",
  },
  {
    key: "6",
    id: "EX628151",
    expenseName: "Water Bill",
    description: "Monthly water charges",
    category: "Utilities",
    date: "22 May 2024",
    amount: "$100",
    invoiceNo: "INV681540",
    paymentMethod: "Cash",
  },
  {
    key: "7",
    id: "EX628152",
    expenseName: "Office Rent",
    description: "Monthly office rent",
    category: "Rent",
    date: "01 Jun 2024",
    amount: "$3000",
    invoiceNo: "INV681541",
    paymentMethod: "Bank Transfer",
  },
  {
    key: "8",
    id: "EX628153",
    expenseName: "Cleaning Services",
    description: "Monthly cleaning services",
    category: "Services",
    date: "05 Jun 2024",
    amount: "$500",
    invoiceNo: "INV681542",
    paymentMethod: "Cash",
  },
  {
    key: "9",
    id: "EX628154",
    expenseName: "Software Subscription",
    description: "Annual software subscription",
    category: "Software",
    date: "10 Jun 2024",
    amount: "$1200",
    invoiceNo: "INV681543",
    paymentMethod: "Credit",
  },
  {
    key: "10",
    id: "EX628155",
    expenseName: "Marketing Expenses",
    description: "Advertising and marketing",
    category: "Marketing",
    date: "15 Jun 2024",
    amount: "$800",
    invoiceNo: "INV681544",
    paymentMethod: "Debit",
  },
];
