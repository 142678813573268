export const classRoom= [
	{
		id: "R167648",
		roomNo: "101",
		capacity: "50",
		status: "Active",
		key:1
	},
	{
		id: "R167647",
		roomNo: "102",
		capacity: "40",
		status: "Active",
		key:2
	},
	{
		id: "R167646",
		roomNo: "103",
		capacity: "60",
		status: "Active",
		key:3
	},
	{
		id: "R167645",
		roomNo: "104",
		capacity: "50",
		status: "Active",
		key:4
	},
	{
		id: "R167644",
		roomNo: "105",
		capacity: "40",
		status: "Active",
		key:5
	},
	{
		id: "R167643",
		roomNo: "106",
		capacity: "50",
		status: "Active",
		key:6
	},
	{
		id: "R167642",
		roomNo: "107",
		capacity: "40",
		status: "Active",
		key:7
	},
	{
		id: "R167641",
		roomNo: "108",
		capacity: "40",
		status: "Active",
		key:8
	},
	{
		id: "R167640",
		roomNo: "109",
		capacity: "40",
		status: "Active",
		key:9
	},
	{
		id: "R167639",
		roomNo: "110",
		capacity: "50",
		status: "Active",
		key:10
	}
];
