export const transportPickup = [
    {
      key: "1",
      id: "PP124556",
      pickupPoint: "2603 Wood Duck Drive Marquette, MI",
      status: "Active",
      statusClass: "badge badge-soft-success",
      addedOn: "15 May 2024",
    },
    {
      key: "2",
      id: "PP124555",
      pickupPoint: "3521 Harvest Lane Kansas City, MO",
      status: "Active",
      statusClass: "badge badge-soft-success",
      addedOn: "14 May 2024",
    },
    {
      key: "3",
      id: "PP124554",
      pickupPoint: "4025 Khale Street, Folly Beach, SC",
      status: "Active",
      statusClass: "badge badge-soft-success",
      addedOn: "13 May 2024",
    },
    {
      key: "4",
      id: "PP124553",
      pickupPoint: "2261 Sweetwood Drive, Denver, CO",
      status: "Active",
      statusClass: "badge badge-soft-success",
      addedOn: "12 May 2024",
    },
    {
      key: "5",
      id: "PP124552",
      pickupPoint: "3341 Palmer Road, Columbus, OH",
      status: "Active",
      statusClass: "badge badge-soft-success",
      addedOn: "11 May 2024",
    },
    {
      key: "6",
      id: "PP124551",
      pickupPoint: "1609 Smith Street, Worcester, MA",
      status: "Active",
      statusClass: "badge badge-soft-success",
      addedOn: "10 May 2024",
    },
    {
      key: "7",
      id: "PP24550",
      pickupPoint: "3167 Stadium Drive, Worcester, MA",
      status: "Active",
      statusClass: "badge badge-soft-success",
      addedOn: "09 May 2024",
    },
    {
      key: "8",
      id: "PP24549",
      pickupPoint: "4650 Aviation Way, Los Angeles, CA",
      status: "Inactive",
      statusClass: "badge badge-soft-danger",
      addedOn: "08 May 2024",
    },
    {
      key: "9",
      id: "PP124548",
      pickupPoint: "2693 Parker Drive, Cleveland, OH",
      status: "Active",
      statusClass: "badge badge-soft-success",
      addedOn: "07 May 2024",
    },
    {
      key: "10",
      id: "PP124547",
      pickupPoint: "2233 Wood Street, Slidell, LA",
      status: "Active",
      statusClass: "badge badge-soft-success",
      addedOn: "07 May 2024",
    },
  ];