export const grade_report_data = [
  {
    key: "1",
    admissionNo: "AD9892434",
    studentName: "Janet",
    rollNo: "35013",
    avatar: "assets/img/students/student-01.jpg",
    english: 95,
    spanish: 93,
    physics: 88,
    chemistry: 90,
    maths: 85,
    computer: 98,
    envScience: 95,
    total: 644,
    percent: 92,
    grade: "O",
  },
  {
    key: "2",
    admissionNo: "AD9892433",
    studentName: "Joann",
    rollNo: "35012",
    avatar: "assets/img/students/student-02.jpg",
    english: 30,
    spanish: 35,
    physics: 36,
    chemistry: 28,
    maths: 38,
    computer: 40,
    envScience: 37,
    total: 244,
    percent: 34,
    grade: "F",
    textColor: "danger",
  },
  {
    key: "3",
    admissionNo: "AD9892432",
    studentName: "Kathleen",
    rollNo: "35011",
    avatar: "assets/img/students/student-03.jpg",
    english: 70,
    spanish: 80,
    physics: 85,
    chemistry: 78,
    maths: 82,
    computer: 83,
    envScience: 80,
    total: 558,
    percent: 79,
    grade: "A",
  },
  {
    key: "4",
    admissionNo: "AD9892431",
    studentName: "Gifford",
    rollNo: "35010",
    avatar: "assets/img/students/student-04.jpg",
    english: 60,
    spanish: 68,
    physics: 65,
    chemistry: 70,
    maths: 67,
    computer: 72,
    envScience: 75,
    total: 477,
    percent: 68,
    grade: "B+",
  },
  {
    key: "5",
    admissionNo: "AD9892430",
    studentName: "Lisa",
    rollNo: "35009",
    avatar: "assets/img/students/student-05.jpg",
    english: 36,
    spanish: 30,
    physics: 40,
    chemistry: 38,
    maths: 50,
    computer: 48,
    envScience: 43,
    total: 285,
    percent: 40,
    grade: "F",
    textColor: "danger",
  },
  {
    key: "6",
    admissionNo: "AD9892429",
    studentName: "Ralph",
    rollNo: "35008",
    avatar: "assets/img/students/student-06.jpg",
    english: 43,
    spanish: 50,
    physics: 62,
    chemistry: 70,
    maths: 65,
    computer: 58,
    envScience: 60,
    total: 408,
    percent: 58,
    grade: "B",
  },
  {
    key: "7",
    admissionNo: "AD9892428",
    studentName: "Julie",
    rollNo: "35007",
    avatar: "assets/img/students/student-07.jpg",
    english: 72,
    spanish: 80,
    physics: 75,
    chemistry: 78,
    maths: 84,
    computer: 87,
    envScience: 76,
    total: 552,
    percent: 78,
    grade: "A",
  },
  {
    key: "8",
    admissionNo: "AD9892427",
    studentName: "Ryan",
    rollNo: "35006",
    avatar: "assets/img/students/student-08.jpg",
    english: 40,
    spanish: 48,
    physics: 42,
    chemistry: 47,
    maths: 32,
    computer: 58,
    envScience: 50,
    total: 317,
    percent: 45,
    grade: "F",
    textColor: "danger",
  },
  {
    key: "9",
    admissionNo: "AD9892426",
    studentName: "Susan",
    rollNo: "35004",
    avatar: "assets/img/students/student-09.jpg",
    english: 60,
    spanish: 62,
    physics: 65,
    chemistry: 70,
    maths: 72,
    computer: 63,
    envScience: 78,
    total: 470,
    percent: 67,
    grade: "B+",
  },
  {
    key: "10",
    admissionNo: "AD9892425",
    studentName: "Richard",
    rollNo: "35003",
    avatar: "assets/img/students/student-10.jpg",
    english: 72,
    spanish: 78,
    physics: 85,
    chemistry: 83,
    maths: 86,
    computer: 90,
    envScience: 92,
    total: 586,
    percent: 83,
    grade: "A+",
  },
];
