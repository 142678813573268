export const staffsAttendance = [
  {
    rollNo: "01",
    Jun: "green",
    Jul: "green",
    Aug: "green",
    Sep: "green",
    Oct: "green",
    Nov: "green",
    Dec: "green",
    Jan: "green",
    Feb:"green",
    img: "assets/img/students/student-01.jpg",
    key: 1,
  },
  {
    rollNo: "02",
    Jun: "green",
    Jul: "red",
    Aug: "green",
    Sep: "green",
    Oct: "green",
    Nov: "green",
    Dec: "green",
    Jan: "green",
    Feb:"green",
    img: "assets/img/students/student-02.jpg",
    key: 2,
  },
  {
    rollNo: "03",
    Jun: "green",
    Jul: "green",
    Aug: "green",
    Sep: "green",
    Oct: "green",
    Nov: "green",
    Dec: "green",
    Jan: "green",
    Feb:"green",
    img: "assets/img/students/student-03.jpg",
    key: 3,
  },
  {
    rollNo: "04",
    Jun: "green",
    Jul: "green",
    Aug: "green",
    Sep: "red",
    Oct: "blue",
    Nov: "green",
    Dec: "green",
    Jan: "green",
    Feb:"green",
    img: "assets/img/students/student-04.jpg",
    key: 4,
  },
  {
    rollNo: "05",
    Jun: "",
    Jul: "",
    Aug: "",
    Sep: "",
    Oct: "",
    Nov: "",
    Dec: "",
    Jan: "",
    Feb:"",
    img: "assets/img/students/student-05.jpg",
    key: 5,
  },
  {
    rollNo: "06",
    Jun: "",
    Jul: "",
    Aug: "",
    Sep: "",
    Oct: "",
    Nov: "",
    Dec: "",
    Jan: "",
    Feb:"",
    img: "assets/img/students/student-06.jpg",
    key: 6,
  },
  {
    rollNo: "07",
    Jun: "green",
    Jul: "green",
    Aug: "green",
    Sep: "green",
    Oct: "green",
    Nov: "green",
    Dec: "green",
    Jan: "green",
    Feb:"green",
    img: "assets/img/students/student-07.jpg",
    key: 7,
  },
  {
    rollNo: "08",
    Jun: "green",
    Jul: "green",
    Aug: "green",
    Sep: "green",
    Oct: "green",
    Nov: "green",
    Dec: "green",
    Jan: "green",
    Feb:"green",
    img: "assets/img/students/student-08.jpg",
    key: 8,
  },
  {
    rollNo: "09",
    Jun: "green",
    Jul: "green",
    Aug: "green",
    Sep: "green",
    Oct: "green",
    Nov: "red",
    Dec: "green",
    Jan: "green",
    Feb:"green",
    img: "assets/img/students/student-09.jpg",
    key: 9,
  },
  {
    rollNo: "10",
    Jun: "",
    Jul: "",
    Aug: "",
    Sep: "",
    Oct: "",
    Nov: "",
    Dec: "",
    Jan: "",
    Feb:"",
    img: "assets/img/students/student-09.jpg",
    key: 10,
  },
  {
    rollNo: "11",
    Jun: "green",
    Jul: "green",
    physics: "green",
    Sep: "green",
    Oct: "green",
    Nov: "green",
    Dec: "green",
    Jan: "green",
    Feb:"green",
    img: "assets/img/students/student-09.jpg",
    key: 11,
  },
  {
    rollNo: "12",
    Jun: "green",
    Jul: "green",
    physics: "green",
    Sep: "green",
    Oct: "green",
    Nov: "green",
    Dec: "green",
    Jan: "green",
    Feb:"green",
    img: "assets/img/students/student-09.jpg",
    key: 12,
  },
  {
    rollNo: "13",
    Jun: "green",
    Jul: "green",
    physics: "green",
    Sep: "green",
    Oct: "green",
    Nov: "green",
    Dec: "green",
    Jan: "green",
    Feb:"green",
    img: "assets/img/students/student-09.jpg",
    key: 13,
  },
];
