export const librarymemberList = [
    {
      key: "1",
      id: "LM823748",
      name: "James",
      img: "assets/img/members/members-01.jpg",
      cardNo: "501",
      email: '"james@example.com',
      dateofJoin: "22 Apr 2024",
      mobile: "+1 78429 82414",
    },
    {
      key: "2",
      id: "LM823747",
      name: "Garcia",
      img: "assets/img/members/members-02.jpg",
      cardNo: "502",
      email: "garcia@example.com",
      dateofJoin: "30 Apr 2024",
      mobile: "+1 37489 46485",
    },
    {
      key: "3",
      id: "LM823746",
      name: "Frank",
      img: "assets/img/members/members-03.jpg",
      cardNo: "503",
      email: "frank@example.com",
      dateofJoin: "05 May 2024",
      mobile: "+1 87651 64816",
    },
    {
      key: "4",
      id: "LM823745",
      name: "Jennie",
      img: "assets/img/members/members-04.jpg",
      cardNo: "504",
      email: "jennie@example.com",
      dateofJoin: "16 May 2024",
      mobile: "+1 49879 86498",
    },
    {
      key: "5",
      id: "LM823744",
      name: "Paul",
      img: "assets/img/members/members-05.jpg",
      cardNo: "505",
      email: "paul@example.com",
      dateofJoin: "28 May 2024",
      mobile: "+1 69787 87984",
    },
    {
      key: "6",
      id: "LM823743",
      name: "Elaine",
      img: "assets/img/members/members-06.jpg",
      cardNo: "506",
      email: "elaine@example.com",
      dateofJoin: "06 Jun 2024",
      mobile: "+1 98764 84984",
    },
    {
      key: "7",
      id: "LM823742",
      name: "Jackson",
      img: "assets/img/members/members-07.jpg",
      cardNo: "507",
      email: "jackson@example.com",
      dateofJoin: "10 Jun 2024",
      mobile: "+1 46876 55498",
    },
    {
      key: "8",
      id: "LM823741",
      name: "Kerry",
      img: "assets/img/members/members-08.jpg",
      cardNo: "508",
      email: "kerry@example.com",
      dateofJoin: "18 Jun 2024",
      mobile: "+1 79468 87467",
    },
    {
      key: "9",
      id: "LM823740",
      name: "Roger",
      img: "assets/img/members/members-09.jpg",
      cardNo: "509",
      email: "roger@example.com",
      dateofJoin: "20 Jul 2024",
      mobile: "+1 65598 64658",
    },
    {
      key: "10",
      id: "LM823739",
      name: "Denise",
      img: "assets/img/members/members-10.jpg",
      cardNo: "510",
      email: "denise@example.com",
      dateofJoin: "26 Jul 2024",
      mobile: "+1 57866 68746",
    },
  ];