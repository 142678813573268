export const studentAttendance= [
    {
        admissionNo: "AD9892434",
        rollNo: 35013,
        name: "Janet",
        class: "III",
        section: "A",
        img : "assets/img/students/student-01.jpg",
        notes: "",
        attendance:"",
        present:"true",
        Late:"",
        Absent:"",
        Holiday:"",
        Halfday:"",
        key:1
    },
    {
        admissionNo: "AD9892433",
        rollNo: 35012,
        name: "Joann",
        class: "IV",
        section: "B",
        img : "assets/img/students/student-02.jpg",
        notes: "",
        attendance:"",
        present:"true",
        Late:"",
        Absent:"",
        Holiday:"",
        Halfday:"",
        key:2
    },
    {
        admissionNo: "AD9892432",
        rollNo: 35011,
        name: "Kathleen",
        class: "II",
        section: "A",
        notes: "",
        attendance:"",
        present:"true",
        Late:"",
        Absent:"",
        Holiday:"",
        Halfday:"",
        img : "assets/img/students/student-03.jpg",
        key:3
    },
    {
        admissionNo: "AD9892431",
        rollNo: 35010,
        name: "Gifford",
        class: "I",
        section: "B",
        notes: "",
        attendance:"",
        present:"1",
        Late:"true",
        Absent:"",
        Holiday:"",
        Halfday:"",
        img : "assets/img/students/student-04.jpg",
        key:4
    },
    {
        admissionNo: "AD9892430",
        rollNo: 35009,
        name: "Lisa",
        class: "II",
        section: "B",
        notes: "",
        attendance:"",
        present:"1",
        Late:"",
        Absent:"",
        Holiday:"true",
        Halfday:"",
        img : "assets/img/students/student-05.jpg",
        key:5
    },
    {
        admissionNo: "AD9892429",
        rollNo: 35008,
        name: "Ralph",
        class: "II",
        section: "B",
        notes: "",
        img : "assets/img/students/student-06.jpg",
        attendance:"",
        present:"1",
        Late:"",
        Absent:"",
        Holiday:"",
        Halfday:"true",
        key:6
    },
    {
        admissionNo: "AD9892428",
        rollNo: 35007,
        name: "Julie",
        class: "V",
        section: "A",
        notes: "",
        img : "assets/img/students/student-07.jpg",
        attendance:"",
        present:"",
        Late:"true",
        Absent:"",
        Holiday:"",
        Halfday:"",
        key:7
    },
    {
        admissionNo: "AD9892427",
        rollNo: 35006,
        name: "Ryan",
        class: "VI",
        section: "A",
        notes: "",
        img : "assets/img/students/student-08.jpg",
        attendance:"",
        present:"true",
        Late:"",
        Absent:"",
        Holiday:"",
        Halfday:"",
        key:8
    },
    {
        admissionNo: "AD9892426",
        rollNo: 35005,
        name: "Susan",
        class: "VIII",
        section: "B",
        notes: "",
        img : "assets/img/students/student-09.jpg",
        attendance:"",
        present:"",
        Late:"",
        Absent:"",
        Holiday:"",
        Halfday:"",
        key:9
    },
    {
        admissionNo: "AD9892425",
        rollNo: 35004,
        name: "Richard",
        class: "VII",
        section: "B",
        notes: "",
        img : "assets/img/students/student-07.jpg",
        attendance:"",
        present:"1",
        Late:"",
        Absent:"",
        Holiday:"",
        Halfday:"",
        key:10
    }
];
