export const accounts_income_data = [
  {
    key: "1",
    id: "I639248",
    incomeName: "April Month Fees",
    description: "Tuition for Term 1, Class II",
    source: "Tuition Fees",
    date: "25 Apr 2024",
    amount: "$15,000",
    invoiceNo: "INV681537",
    paymentMethod: "Cash",
  },
  {
    key: "2",
    id: "I639247",
    incomeName: "STEM Program Grant",
    description: "Annual funding for STEM programs",
    source: "Government Grants",
    date: "29 Apr 2024",
    amount: "$20,000",
    invoiceNo: "INV681536",
    paymentMethod: "Credit",
  },
  {
    key: "3",
    id: "I639246",
    incomeName: "Alumni Scholarship",
    description: "Donation from Alumni for scholarships",
    source: "Donations",
    date: "11 May 2024",
    amount: "$1000",
    invoiceNo: "INV681535",
    paymentMethod: "Cash",
  },
  {
    key: "4",
    id: "I639245",
    incomeName: "Uniform Sales",
    description: "Sale of school uniforms",
    source: "Merchandise",
    date: "16 May 2024",
    amount: "$10,500",
    invoiceNo: "INV681534",
    paymentMethod: "Cash",
  },
  {
    key: "5",
    id: "I639244",
    incomeName: "Event Parking Fees",
    description: "Monthly parking fees for external users",
    source: "Parking Fees",
    date: "21 May 2024",
    amount: "$8000",
    invoiceNo: "INV681533",
    paymentMethod: "Cash",
  },
  {
    key: "6",
    id: "I639243",
    incomeName: "Football Season Pass",
    description: "Season passes for school football games",
    source: "Sports",
    date: "14 Jun 2024",
    amount: "$7000",
    invoiceNo: "INV681532",
    paymentMethod: "Credit",
  },
  {
    key: "7",
    id: "I639242",
    incomeName: "Summer Reading Sale",
    description: "Sales from summer reading book fair",
    source: "Book Fair",
    date: "20 Jun 2024",
    amount: "$3000",
    invoiceNo: "INV681531",
    paymentMethod: "Cash",
  },
  {
    key: "8",
    id: "I639241",
    incomeName: "Library Donation",
    description: "Funds donated for library improvements",
    source: "Donations",
    date: "24 Jun 2024",
    amount: "$2000",
    invoiceNo: "INV681530",
    paymentMethod: "Cash",
  },
  {
    key: "9",
    id: "I639240",
    incomeName: "Cafeteria Income",
    description: "Monthly Cafeteria Income",
    source: "Cafeteria",
    date: "08 Jul 2024",
    amount: "$15,000",
    invoiceNo: "INV681529",
    paymentMethod: "Credit",
  },
  {
    key: "10",
    id: "I639239",
    incomeName: "Daily Visitor Parking",
    description: "Parking fees collected from visitors",
    source: "Parking Fees",
    date: "13 Jul 2024",
    amount: "$4000",
    invoiceNo: "INV681537",
    paymentMethod: "Cash",
  },
];
