export const testimonials_data = [
  {
    key: '1',
    id: 'T346285',
    author: { name: 'Thomas', avatar: 'assets/img/parents/parent-01.jpg' },
    role: 'Parent',
    content: "I'm impressed with how easy it is to track my child's progress using this system.",
    dateAdded: '25 Apr 2024',
  },
  {
    key: '2',
    id: 'T346284',
    author: { name: 'Teresa', avatar: 'assets/img/teachers/teacher-01.jpg' },
    role: 'Teacher',
    content: 'The gradebook feature has streamlined my grading process.',
    dateAdded: '28 Apr 2024',
  },
  {
    key: '3',
    id: 'T346283',
    author: { name: 'Veronica', avatar: 'assets/img/students/student-11.jpg' },
    role: 'Student',
    content: 'I find the timetable feature very helpful in keeping track of my classes and assignments.',
    dateAdded: '02 May 2024',
  },
  {
    key: '4',
    id: 'T346282',
    author: { name: 'Kevin', avatar: 'assets/img/profiles/avatar-27.jpg' },
    role: 'Admin',
    content: 'Our school has seen a significant improvement in efficiency since implementing this system.',
    dateAdded: '14 May 2024',
  },
  {
    key: '5',
    id: 'T346281',
    author: { name: 'Claudia', avatar: 'assets/img/parents/parent-04.jpg' },
    role: 'Parent',
    content: 'The communication tools have made it much easier to stay informed about school events.',
    dateAdded: '20 May 2024',
  },
  {
    key: '6',
    id: 'T346280',
    author: { name: 'Hellana', avatar: 'assets/img/teachers/teacher-03.jpg' },
    role: 'Teacher',
    content: 'I appreciate the support provided by the technical team in customizing the system.',
    dateAdded: '05 Jun 2024',
  },
  {
    key: '7',
    id: 'T346285',
    author: { name: 'Thomas', avatar: 'assets/img/parents/parent-01.jpg' },
    role: 'Parent',
    content: "I'm impressed with how easy it is to track my child's progress using this system.",
    dateAdded: '25 Apr 2024',
  },
  {
    key: '8',
    id: 'T346284',
    author: { name: 'Teresa', avatar: 'assets/img/teachers/teacher-01.jpg' },
    role: 'Teacher',
    content: 'The gradebook feature has streamlined my grading process.',
    dateAdded: '28 Apr 2024',
  },
  {
    key: '9',
    id: 'T346283',
    author: { name: 'Veronica', avatar: 'assets/img/students/student-11.jpg' },
    role: 'Student',
    content: 'I find the timetable feature very helpful in keeping track of my classes and assignments.',
    dateAdded: '02 May 2024',
  },
  {
    key: '10',
    id: 'T346282',
    author: { name: 'Kevin', avatar: 'assets/img/profiles/avatar-27.jpg' },
    role: 'Admin',
    content: 'Our school has seen a significant improvement in efficiency since implementing this system.',
    dateAdded: '14 May 2024',
  },
];
