export const fees_report_data = [
  {
    key: "1",
    feesGroup: "Class 1 General",
    feesDescription: "(Admission Fees)",
    feesCode: "admission-fees",
    dueDate: "25 Mar 2024",
    amount: 2000,
    status: "Paid",
    refId: "#435454",
    mode: "Cash",
    datePaid: "25 Jan 2024",
    discount: "10%",
    fine: 200,
    balance: 0,
  },
  {
    key: "2",
    feesGroup: "Class 1 General",
    feesDescription: "(Mar month Fees)",
    feesCode: "mar-month-fees",
    dueDate: "10 Apr 2024",
    amount: 2500,
    status: "Paid",
    refId: "#435453",
    mode: "Cash",
    datePaid: "03 Apr 2024",
    discount: "10%",
    fine: 0,
    balance: 0,
  },
  {
    key: "3",
    feesGroup: "Class 1 General",
    feesDescription: "(Apr month Fees)",
    feesCode: "apr-month-fees",
    dueDate: "10 May 2024",
    amount: 2500,
    status: "Paid",
    refId: "#435453",
    mode: "Cash",
    datePaid: "03 Apr 2024",
    discount: "10%",
    fine: 0,
    balance: 0,
  },
  {
    key: "4",
    feesGroup: "Class 1 General",
    feesDescription: "(May month Fees)",
    feesCode: "may-month-fees",
    dueDate: "10 Jun 2024",
    amount: 2500,
    status: "Paid",
    refId: "#435451",
    mode: "Cash",
    datePaid: "02 Jun 2024",
    discount: "10%",
    fine: 200,
    balance: 0,
  },
  {
    key: "5",
    feesGroup: "Class 1 General",
    feesDescription: "(Jun month Fees)",
    feesCode: "jun-month-fees",
    dueDate: "10 Jul 2024",
    amount: 2500,
    status: "Paid",
    refId: "#435450",
    mode: "Cash",
    datePaid: "05 Jul 2024",
    discount: "10%",
    fine: 200,
    balance: 0,
  },
  {
    key: "6",
    feesGroup: "Class 1 General",
    feesDescription: "(Jul month Fees)",
    feesCode: "jul-month-fees",
    dueDate: "10 Aug 2024",
    amount: 2500,
    status: "Paid",
    refId: "#435449",
    mode: "Cash",
    datePaid: "01 Aug 2024",
    discount: "10%",
    fine: 200,
    balance: 0,
  },
  {
    key: "7",
    feesGroup: "Class 1 General",
    feesDescription: "(Dec month Fees)",
    feesCode: "dec-month-fees",
    dueDate: "10 Jan 2024",
    amount: 2500,
    status: "Paid",
    refId: "#435443",
    mode: "Cash",
    datePaid: "05 Jan 2024",
    discount: "10%",
    fine: 0,
    balance: 0,
  },
  {
    key: "8",
    feesGroup: "Class 1 General",
    feesDescription: "(Jan month Fees)",
    feesCode: "jan-month-fees",
    dueDate: "10 Feb 2024",
    amount: 2000,
    status: "Paid",
    refId: "#435443",
    mode: "Cash",
    datePaid: "01 Feb 2024",
    discount: "10%",
    fine: 200,
    balance: 0,
  },
  {
    key: "9",
    feesGroup: "",
    feesDescription: "",
    feesCode: "",
    dueDate: "",
    amount: 2000,
    status: "",
    refId: "",
    mode: "",
    datePaid: "",
    discount: 200,
    fine: 200,
    balance: 0,
  },
];
