export const Attendance = [
    {
        key:"1",
        date:"01",
        jan:"1",
        feb:"1",
        mar:"1",
        apr:"1",
        may:"2",
        jun:"3",
        jul:"4",
        aug:"5",
        sep:"1",
        oct:"1",
        nov:"1",
        dec:"1",
        
    },
    {
        key:"2",
        date:"02",
        jan:"1",
        feb:"1",
        mar:"1",
        apr:"1",
        may:"1",
        jun:"2",
        jul:"3",
        aug:"5",
        sep:"5",
        oct:"1",
        nov:"3",
        dec:"1",
        
    },
    {
        key:"3",
        date:"03",
        jan:"1",
        feb:"1",
        mar:"1",
        apr:"1",
        may:"1",
        jun:"2",
        jul:"3",
        aug:"1",
        sep:"1",
        oct:"1",
        nov:"3",
        dec:"1",
        
    },
    {
        key:"4",
        date:"04",
        jan:"1",
        feb:"1",
        mar:"1",
        apr:"1",
        may:"1",
        jun:"1",
        jul:"1",
        aug:"1",
        sep:"5",
        oct:"1",
        nov:"3",
        dec:"1",
        
    },
    {
        key:"6",
        date:"06",
        jan:"5",
        feb:"4",
        mar:"1",
        apr:"1",
        may:"1",
        jun:"1",
        jul:"1",
        aug:"1",
        sep:"1",
        oct:"1",
        nov:"3",
        dec:"1",
        
    },
    {
        key:"7",
        date:"07",
        jan:"1",
        feb:"3",
        mar:"1",
        apr:"5",
        may:"1",
        jun:"2",
        jul:"3",
        aug:"1",
        sep:"3",
        oct:"1",
        nov:"3",
        dec:"1",
        
    }, {
        key:"8",
        date:"08",
        jan:"1",
        feb:"1",
        mar:"1",
        apr:"1",
        may:"2",
        jun:"3",
        jul:"4",
        aug:"5",
        sep:"1",
        oct:"1",
        nov:"1",
        dec:"1",
        
    },
    {
        key:"9",
        date:"09",
        jan:"1",
        feb:"1",
        mar:"1",
        apr:"1",
        may:"1",
        jun:"2",
        jul:"3",
        aug:"5",
        sep:"5",
        oct:"1",
        nov:"3",
        dec:"1",
        
    },
    {
        key:"10",
        date:"10",
        jan:"1",
        feb:"1",
        mar:"1",
        apr:"1",
        may:"1",
        jun:"2",
        jul:"3",
        aug:"1",
        sep:"1",
        oct:"1",
        nov:"3",
        dec:"1",
        
    },
]