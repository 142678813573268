export const feesType = [
    {
      key: "1",
      id: "FG80482",
      feesType: "Admission Fees",
      feesCode: "Admission-Fees",
      feesGroup: "Tuition Fees",
      description: "The money that you pay to be taught",
      status: "Active",
      statusClass: "badge badge-soft-success",
    },
    {
      key: "2",
      id: "FG80481",
      feesType: "Apr-Mar",
      feesCode: "Apr-Mar",
      feesGroup: "Monthly Fees",
      description: "The money that you pay to be taught",
      status: "Active",
      statusClass: "badge badge-soft-success",
    },
    {
      key: "3",
      id: "FG80480",
      feesType: "Bus Fees",
      feesCode: "Bus-Fees",
      feesGroup: "Class 1 General",
      description: "The money that you pay to be taught",
      status: "Active",
      statusClass: "badge badge-soft-success",
    },
    {
      key: "4",
      id: "FG80479",
      feesType: "1st Installment Fees",
      feesCode: "1st-Installment-Fees",
      feesGroup: "Class 1 Lump Sum",
      description: "The money that you pay to be taught",
      status: "Active",
      statusClass: "badge badge-soft-success",
    },
    {
      key: "5",
      id: "FG80478",
      feesType: "2nd Installment Fees",
      feesCode: "2nd-Installment-Fees",
      feesGroup: "Class 1- I Installment",
      description: "The money that you pay to be taught",
      status: "Inactive",
      statusClass: "badge badge-soft-danger",
    },
    {
      key: "6",
      id: "FG80477",
      feesType: "3rd Installment Fees",
      feesCode: "3rd-Installment-Fees",
      feesGroup: "Class 1-II Installment",
      description: "The money that you pay to be taught",
      status: "Active",
      statusClass: "badge badge-soft-success",
    },
    {
      key: "7",
      id: "FG80476",
      feesType: "4th Installment Fees",
      feesCode: "3rd-Installment-Fees",
      feesGroup: "Class 1-III Installment",
      description: "The money that you pay to be taught",
      status: "Active",
      statusClass: "badge badge-soft-success",
    },
    {
      key: "8",
      id: "FG80475",
      feesType: "Topper Discount",
      feesCode: "3rd-Installment-Fees",
      feesGroup: "Discount",
      description: "The money that you pay to be taught",
      status: "Inactive",
      statusClass: "badge badge-soft-danger",
    },
    {
      key: "9",
      id: "FG80474",
      feesType: "3rd Installment Fees",
      feesCode: "3rd-Installment-Fees",
      feesGroup: "Class 3- I Installment",
      description: "The money that you pay to be taught",
      status: "Active",
      statusClass: "badge badge-soft-success",
    },
    {
      key: "10",
      id: "FG80473",
      feesType: "4th Installment Fees",
      feesCode: "4th-Installment-Fees",
      feesGroup: "Class 4- I Installment",
      description: "The money that you pay to be taught",
      status: "Active",
      statusClass: "badge badge-soft-success",
    },
  ];