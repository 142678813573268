export const bookList = [
    {
      key: "1",
      id: "LB864723",
      bookName: "Echoes of Eternity",
      bookNo: "501",
      publisher: "Aurora Press",
      author: "Isabella Rivers",
      subject: "History",
      rackNo: "6550",
      qty: "150",
      available: "120",
      price: "$300",
      postDate: "25 Apr 2024",
    },
    {
      key: "2",
      id: "LB864722",
      bookName: "The Stars of Eldorado",
      bookNo: "502",
      publisher: "Nebula Press",
      author: "Amanda Grayson",
      subject: "Science",
      rackNo: "6551",
      qty: "200",
      available: "180",
      price: "$280",
      postDate: "28 Apr 2024",
    },
    {
      key: "3",
      id: "LB864722",
      bookName: "The Glass Painter",
      bookNo: "503",
      publisher: "Artisan Reads",
      author: "Isabel Marquez",
      subject: "Literary",
      rackNo: "6552",
      qty: "180",
      available: "160",
      price: "$320",
      postDate: "04 May 2024",
    },
    {
      key: "4",
      id: "LB864720",
      bookName: "Beyond the Edge",
      bookNo: "504",
      publisher: "Explorer's Press",
      author: "Leo Finnegan",
      subject: "Adventure",
      rackNo: "6553",
      qty: "120",
      available: "100",
      price: "$350",
      postDate: "18 May 2024",
    },
    {
      key: "5",
      id: "LB864719",
      bookName: "Shadow Symphony",
      bookNo: "505",
      publisher: "Harmony House",
      author: "Claire Vincent",
      subject: "Gothic",
      rackNo: "6554",
      qty: "220",
      available: "160",
      price: "$280",
      postDate: "20 May 2024",
    },
    {
      key: "6",
      id: "LB864718",
      bookName: "The Last Library",
      bookNo: "506",
      publisher: "Archive Publishing",
      author: "Marcus Brewster",
      subject: "Dystopian",
      rackNo: "6555",
      qty: "170",
      available: "150",
      price: "$250",
      postDate: "16 Jun 2024",
    },
    {
      key: "7",
      id: "LB864717",
      bookName: "The Saffron Tide",
      bookNo: "507",
      publisher: "Indus Books",
      author: "Rajiv Anand",
      subject: "History",
      rackNo: "6556",
      qty: "140",
      available: "100",
      price: "$200",
      postDate: "20 Jun 2024",
    },
    {
      key: "8",
      id: "LB864716",
      bookName: "Windswept",
      bookNo: "508",
      publisher: "Coastal Press",
      author: "Lydia Ramsey",
      subject: "Romance",
      rackNo: "6557",
      qty: "300",
      available: "270",
      price: "$300",
      postDate: "24 Jun 2024",
    },
    {
      key: "9",
      id: "LB864715",
      bookName: "Frostbound Throne",
      bookNo: "509",
      publisher: "Frozen Realms",
      author: "Viktor Korneev",
      subject: "Fantasy",
      rackNo: "6558",
      qty: "320",
      available: "200",
      price: "$350",
      postDate: "$10 Jul 2024",
    },
    {
      key: "10",
      id: "LB864714",
      bookName: "The Last Alchemist",
      bookNo: "510",
      publisher: "Alchemy Arts",
      author: "Philip Dubois",
      subject: "Mystery",
      rackNo: "6559",
      qty: "190",
      available: "170",
      price: "$400",
      postDate: "12 Jul 2024",
    },
  ];