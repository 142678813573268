export const collectFessData = [
    {
      key: "1",
      admNo: "AD124556",
      rollNo: "55365",
      student: "Janet",
      studentClass: "III, A",
      class: "III",
      section: "A",
      studentImage: "assets/img/students/student-01.jpg",
      parentImage: "parent-01.jpg",
      parent: "Thomas",
      parentClass: "Added on 25 Mar 2024",
      email: "thomas@example.com",
      amount: "2000",
      lastDate: "15 May 2024",
      status: "Paid",
      statusClass: "badge badge-soft-success",
      
      
      view: "View Details",
    },
    {
      key: "2",
      admNo: "AD124555",
      rollNo: "12454",
      student: "Joann",
      studentClass: "IV, B",
      class: "IV",
      section: "B",
      studentImage: "assets/img/students/student-02.jpg",
      parentImage: "parent-02.jpg",
      parent: "Marquita",
      parentClass: "Added on 18 Mar 2024",
      email: "marquita@example.com",
      amount: "156",
      lastDate: "15 May 2024",
      status: "Paid",
      statusClass: "badge badge-soft-success",
      
      
      view: "View Details",
    },
    {
      key: "3",
      admNo: "AD124554",
      rollNo: "65454",
      student: "Kathleen",
      studentClass: "III, A",
      class: "III",
      section: "A",
      studentImage: "assets/img/students/student-03.jpg",
      parentImage: "parent-03.jpg",
      parent: "Johnson",
      parentClass: "Added on 14 Mar 2024",
      email: "johnson@example.com",
      amount: "645",
      lastDate: "15 May 2024",
      status: "Paid",
      statusClass: "badge badge-soft-success",
      
      
      view: "View Details",
    },
    {
      key: "4",
      admNo: "AD124553",
      rollNo: "78787",
      student: "Gifford",
      studentClass: "I, B",
      class: "I",
      section: "B",
      studentImage: "assets/img/students/student-04.jpg",
      parentImage: "parent-04.jpg",
      parent: "Claudia",
      parentClass: "Added on 27 Feb 2024",
      email: "claudia@example.com",
      amount: "456",
      lastDate: "15 May 2024",
      status: "Unpaid",
      statusClass: "badge badge-soft-danger",
      
      view: "Collect Fees",
    },
    {
      key: "5",
      admNo: "AD124552",
      rollNo: "31564",
      student: "Lisa",
      studentClass: "II, B",
      class: "II",
      section: "B",
      studentImage: "assets/img/students/student-05.jpg",
      parentImage: "parent-05.jpg",
      parent: "Arthur",
      parentClass: "Added on 11 Feb 2024",
      email: "arthur@example.com",
      amount: "645",
      lastDate: "15 May 2024",
      status: "Unpaid",
      statusClass: "badge badge-soft-danger",
      
      view: "Collect Fees",
    },
    {
      key: "6",
      admNo: "AD124551",
      rollNo: "78456",
      student: "Ralph",
      studentClass: "III, B",
      class: "III",
      section: "B",
      studentImage: "assets/img/students/student-06.jpg",
      parentImage: "parent-06.jpg",
      parent: "Colleen",
      parentClass: "Added on 24 Jan 2024",
      email: "colleen@example.com",
      amount: "156",
      lastDate: "15 May 2024",
      status: "Unpaid",
      statusClass: "badge badge-soft-danger",
      
      view: "Collect Fees",
    },
    {
      key: "7",
      admNo: "AD124550",
      rollNo: "67897",
      student: "Julie",
      studentClass: "V, A",
      class: "III",
      section: "B",
      studentImage: "assets/img/students/student-07.jpg",
      parentImage: "parent-07.jpg",
      parent: "Robert",
      parentClass: "Added on 19 Jan 2024",
      email: "colleen@example.com",
      amount: "156",
      lastDate: "15 May 2024",
      status: "Unpaid",
      statusClass: "badge badge-soft-danger",
      
      view: "Collect Fees",
    },
    {
      key: "8",
      admNo: "P124549",
      rollNo: "47895",
      student: "Ryan",
      studentClass: "VI, A",
      class: "VI",
      section: "A",
      studentImage: "assets/img/students/student-08.jpg",
      parentImage: "parent-08.jpg",
      parent: "Jessie",
      parentClass: "Added on 08 Jan 2024",
      email: "jessie@example.com",
      amount: "645",
      lastDate: "15 May 2024",
      status: "Unpaid",
      statusClass: "badge badge-soft-danger",
      
      view: "Collect Fees",
    },
    {
      key: "9",
      admNo: "AD124548",
      rollNo: "65547",
      student: "Susan",
      studentClass: "VIII, B",
      class: "VIII",
      section: "B",
      studentImage: "assets/img/students/student-09.jpg",
      parentImage: "parent-09.jpg",
      parent: "Michael",
      parentClass: "Added on 22 Dec 2023",
      email: "michael@example.com",
      amount: "456",
      lastDate: "15 May 2024",
      status: "Unpaid",
      statusClass: "badge badge-soft-danger",
      
      view: "View Details",
    },
    {
      key: "10",
      admNo: "AD124548",
      rollNo: "65547",
      student: "Richard",
      studentClass: "Richard",
      class: "VII",
      section: "B",
      studentImage: "assets/img/students/student-10.jpg",
      parentImage: "parent-10.jpg",
      parent: "Mary",
      parentClass: "Added on 15 Dec 2024",
      email: "mary@example.com",
      amount: "456",
      lastDate: "15 May 2024",
      status: "Unpaid",
      statusClass: "badge badge-soft-danger",
      
      view: "Collect Fees",
    },
    {
      key: "11",
      admNo: "AD124548",
      rollNo: "65547",
      student: "Susan",
      studentClass: "VIII, B",
      class: "VIII",
      section: "B",
      studentImage: "assets/img/students/student-09.jpg",
      parentImage: "parent-09.jpg",
      parent: "Michael",
      parentClass: "Added on 22 Dec 2023",
      email: "michael@example.com",
      amount: "456",
      lastDate: "15 May 2024",
      status: "Unpaid",
      statusClass: "badge badge-soft-danger",
      
      view: "Collect Fees",
    },
  ];